import React from "react";
import HeadingAndImage from "../components/HeadingAndImage";
import ImageBubble from "../elements/ImageBubble";
import LetterBubble from "../elements/LetterBubble";
import Section from "../elements/Section";
import TextBubble from "../elements/TextBubble";
import asset from "../images/AssetOne.png";
import welcomeImage from "../images/Youth_Illustrations-01.svg";
import whyImage1 from "../images/Youth_Illustrations-04.svg";
import whyImage2 from "../images/Youth_Illustrations-05.svg";
import whoImage from "../images/Youth_Illustrations-16.svg";
import result from "../images/Youth_Illustrations-17.svg";
import H from "../images/Youth_Illustrations-06.svg";
import E from "../images/Youth_Illustrations-07.svg";
import A1 from "../images/Youth_Illustrations-08.svg";
import R from "../images/Youth_Illustrations-09.svg";
import T from "../images/Youth_Illustrations-13.svg";
import S1 from "../images/Youth_Illustrations-12.svg";
import S2 from "../images/Youth_Illustrations-11.svg";
import M from "../images/Youth_Illustrations-10.svg";
import A2 from "../images/Youth_Illustrations-14.svg";
import P from "../images/Youth_Illustrations-15.svg";
import CIHR from "../images/Youth_Illustrations-CIHR Logo.svg";
import UBC from "../images/Youth_Illustrations-ubc.png";
import BC from "../images/Youth_Illustrations-BC.png";
import anti from "../images/Youth_Illustrations-AG Design.png";
import echo from "../images/Youth_Illustrations-Echo.png";
import arche from "../images/Youth_Illustrations-Arche.png";

const Welcome = () => {
  const data = [
    {
      id: "H",
      letter: "H",
      image: `${H}`,
      text1: "À la maison:",
      text2: "Ta vie familiale et les ressources de ton mileu de vie.",
    },
    {
      id: "E",
      letter: "E",
      image: `${E}`,
      text1: "L'éducation et les activités:",
      text2: "L’école, le travail et tes passe-temps.",
    },
    {
      id: "A1",
      letter: "A",
      image: `${A1}`,
      text1: "L'alcool et les drogues:",
      text2: "Ta consommation d'alcool et de drogues.",
    },
    {
      id: "R",
      letter: "R",
      image: `${R}`,
      text1: "Les relations et l'intimidation:",
      text2: "Tes amitiés et ton réseau social.",
    },
    {
      id: "T",
      letter: "T",
      image: `${T}`,
      text1: "Les pensées et anxiété:",
      text2: "Tes sentiments de stress ou d’angoisse.",
    },
    {
      id: "S1",
      letter: "S",
      image: `${S1}`,
      text1: "La sécurité:",
      text2: "Ta sécurité physique et celle de ton entourage.",
    },
    {
      id: "S2",
      letter: "S",
      image: `${S2}`,
      text1: "La santé sexuelle:",
      text2: "Tes pratiques et tes connaissances en matière de santé sexuelle.",
    },
    {
      id: "M",
      letter: "M",
      image: `${M}`,
      text1: "L'humeur:",
      text2: "Ton humeur et ton comportement.",
    },
    {
      id: "A2",
      letter: "A",
      image: `${A2}`,
      text1: "L'abus:",
      text2:
        "Tes expériences présentes et passées d’abus que tu aurais pu subir.",
    },
    {
      id: "P",
      letter: "P",
      image: `${P}`,
      text1: "Les activités professionnelles et les ressources:",
      text2:
        "Les ressources que tu utilises actuellement et celles qui pourraient t'intéresser",
    },
  ];

  const dataInput = data?.map(({ letter, image, text1, text2, id }) => {
    return (
      <LetterBubble
        id={id}
        letter={letter}
        Children={<ImageBubble image={image} pinktext={text1} desc={text2} />}
      />
    );
  });
  return (
    <>
      <Section passID="welcome">
        <div className="one">
          <h1>Bienvenue à MyHEARTSMAP</h1>
          <img alt="logo" src={asset} />
          <p>
            Besoin de quitter cette page rapidement? Utilisez le bouton de
            sortie rapide.
          </p>
        </div>
        <HeadingAndImage
          heading="Qu'est-ce qu'est MyHEARTSMAP?"
          image={welcomeImage}
          imgwidth="50%"
        />
      </Section>
      <Section passID="why">
        <HeadingAndImage
          heading="Pourquoi MyHEARTSMAP a-t-il été créé?"
          image={whyImage1}
          imgwidth="90%"
        >
          <TextBubble
            textInput="Lorsque tu présentes des symptômes compatibles avec un trouble de santé mentale, l’urgence peut ne pas être la ressource la plus appropriée pour toi."
            backColor="#fff"
          />
        </HeadingAndImage>
        <HeadingAndImage image={whyImage2} imgwidth="60%">
          <TextBubble
            textInput="MyHEARTSMAP a été créé pour évaluer vos besoins en termes de santé mentale et tu mettre en relation avec les ressources appropriées. Et ce, sans les tracas d'une première visite aux urgences."
            backColor="#fff"
          />
        </HeadingAndImage>
      </Section>
      <Section passID="what">
        <HeadingAndImage heading="Qu’évalue MyHEARTSMAP?">
          <p style={{ marginTop: "-20px", marginBottom: "20px" }}>
            MyHEARTSMAP évalue les domaines suivants:{" "}
          </p>
        </HeadingAndImage>
        {dataInput}
      </Section>
      <Section passID="who">
        <HeadingAndImage
          heading="Qui peut le remplir?"
          image={whoImage}
          imgwidth="90%"
        >
          <TextBubble
            textInput="MyHEARTSMAP est très fiable lorsqu'il est rempli par des jeunes de 10 à 17 ans seuls ou par des parents au nom de leur enfant de 6 à 17 ans."
            backColor="transparent"
          />
        </HeadingAndImage>
      </Section>
      <Section passID="results">
        <HeadingAndImage
          heading="Que dois-je faire avec les résultats?"
          image={result}
          imgwidth="100%"
        >
          <TextBubble
            textInput="Tu peux partager les résultats avec tes parents ou ton fournisseur de soins de santé, ou les garder pour toi. Le processus est anonyme et confidentiel."
            backColor="#F2F2F2"
          />
        </HeadingAndImage>
      </Section>
      <Section passID="credits">
        <HeadingAndImage>
          <a
            href="https://apex.med.ubc.ca/ords/pxmyheartsmap/r/myheartsmap/home"
            target="_blank"
            rel="noreferrer"
          >
            Découvrez si MyHEARTSMAP convient à votre enfant.
          </a>
        </HeadingAndImage>
        <div className="footer">
          <p className="footerbanner">
            © 2015 par la Dre Quynh Doan et le Dr Tyler Black
          </p>
          <div className="footer2">
            <div className="footerSection">
              <p className="footerText">
                L’OUTIL MYHEARTSMAP A REÇU L’APPUI DU PROGRAMME DE SUBVENTIONS
                DE PROJET DES INSTITUTS DE RECHERCHE EN SANTÉ DU CANADA (IRSC).
              </p>
              <div className="footerImgArea" id="CIHR">
                <a
                  href="https://cihr-irsc.gc.ca/e/193.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="CIHR" src={CIHR} />
                </a>
              </div>
            </div>
            <div className="footerSection">
              <p className="footerText" style={{ padding: "5px 60px" }}>
                DR LE DR DOAN EST SOUTENU PAR L’UNIVERSITÉ DE LA
                COLOMBIE-BRITANNIQUE ET LE BC CHILDREN’S HOSPITAL RESEARCH
                INSTITUTE.
              </p>
              <div className="footerImgArea" id="UBC">
                <div className="image3">
                  <a href="https://www.ubc.ca" target="_blank" rel="noreferrer">
                    <img alt="UBC" src={UBC} />
                  </a>
                  <a
                    href="https://openheartsmap.bcchr.ca/ords/f?p=211:LOGIN_DESKTOP"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="OpenHeartsMap" src={asset} />
                  </a>
                  <a
                    href="http://www.bcchildrens.ca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="BC" src={BC} />
                  </a>
                </div>
              </div>
            </div>
            <div className="footerSection">
              <p className="footerText">AVEC L'APPUI DE</p>
              <div className="footerImgArea" id="AEA">
                <div className="image2">
                  <div className="image2in1">
                    <a
                      href="https://www.ualberta.ca/pediatrics/pediatric-research/affiliated-research-units/alberta-research-centre-for-health-evidence-arche/index.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="Arche" src={arche} />
                    </a>
                    <a
                      href="http://www.echokt.ca"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="ECHO" src={echo} />
                    </a>
                  </div>
                  <a
                    href="https://www.antigravitydesignco.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="AntiGravityDesign" src={anti} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Welcome;
